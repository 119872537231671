import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import { memo } from 'react';
import { ButtonGroupProps } from './types';

function ButtonGroup({
  onChange,
  selected,
  buttons,
  vertical,
  gap,
  whiteSpace,
  minHeight,
  height,
  width,
  alignCenter
}: ButtonGroupProps) {
  return (
    <Box
      display="inline-flex"
      gap={{ xs: '8px', md: gap || '16px' }}
      flexDirection={vertical ? 'column' : 'inherit'}
      width="100%"
      justifyContent={{
        xs: 'space-between',
        md: alignCenter ? 'center' : 'initial'
      }}
      sx={{
        overflow: 'auto',
        '::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      {buttons.map((button) => (
        <Box key={button.slug} width={width}>
          <Button
            fullWidth
            sx={{
              width: width || '100%',
              minWidth: { xs: '88px', md: '149px' },
              minHeight: { xs: '32px', md: minHeight || '56px' },
              whiteSpace,
              margin: 0,
              height: { xs: '36px', md: height || '56px' },
              backgroundColor:
                selected === button.slug ? 'primary' : 'primaryOverlays.A200',
              fill: 'currentColor',
              color:
                selected === button.slug
                  ? 'primaryOverlays[900]'
                  : 'text.secondary',
              ':hover': {
                backgroundColor:
                  selected === button.slug
                    ? 'primaryOverlays[300]'
                    : 'primaryOverlays.A300',
                color:
                  selected === button.slug
                    ? 'primaryOverlays[900]'
                    : 'text.secondary',
                fill: 'currentColor'
              },
              '.MuiButton-startIcon': {
                display: {
                  xs: 'flex',
                  md: 'block'
                },
                width: 24,
                height: 24,
                marginRight: { xs: '0px', md: '10px' }
              }
            }}
            variant="contained"
            startIcon={button.icon}
            onClick={() =>
              selected !== button.slug ? onChange(button.slug) : null
            }
          >
            <Text
              fontWeight={selected === button.slug ? 500 : 400}
              variant="body2"
              display={{ md: 'initial', width: '100%' }}
              intlPath={button?.titleIntlPath}
            >
              {button.title}
            </Text>
          </Button>
        </Box>
      ))}
    </Box>
  );
}

export default memo(ButtonGroup);
