import { Box, Card } from '@mui/material';
import If from 'components/atoms/If';
import Text from 'components/atoms/Text';
import Image from 'next/image';

export interface CircleIconProps {
  title: string;
  description: string;
  color?: string;
  focusedCardIndex?: number;
  index?: number;
  iconUrl?: string;
  name?: string;
}

const BannerCard: React.FC<CircleIconProps> = ({ ...props }) => {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: '140px',
        padding: `24px`,
        height: '100%',
        boxShadow:
          props.focusedCardIndex === props.index
            ? '0px 0px 16px rgba(185, 148, 249, 0.34)'
            : 'none'
      }}
    >
      <Box width="72px" minWidth="72px" mr={3}>
        <If condition={props.focusedCardIndex !== props.index}>
          <Image
            src={`/images/card-section/${props.name}.png`}
            width={72}
            height={72}
          />
        </If>
        <If condition={props.focusedCardIndex === props.index}>
          <Image
            src={`/images/card-section/${props.name}-focused.png`}
            width={72}
            height={72}
          />
        </If>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Text
          paddingBottom={1}
          color={
            props.focusedCardIndex === props.index
              ? props.color
              : `text.primary`
          }
          fontWeight={700}
          variant="h6"
          textAlign="start"
        >
          {props.title}
        </Text>
        <Text color="text.secondary" textAlign="start">
          {props.description}
        </Text>
      </Box>
    </Card>
  );
};

export default BannerCard;
