/* eslint-disable import/no-named-as-default */
import { ArrowRightIcon } from '@bws-bitfy/icons-react';
import { Box, Grid, keyframes, useTheme } from '@mui/material';
import Button from 'components/atoms/Button';
import Condition from 'components/atoms/Condition';
import Text from 'components/atoms/Text';
import BackgroundGradient from 'components/website/atoms/backgroundGradiant';
import SectionBackground from 'components/website/molecules/sectionBackground';
import { IS_SANDBOX_ENV } from 'constants/environments';
import { Routes } from 'constants/routes';
import useDeviceSize from 'hooks/useDeviceSize';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

const floatTop = keyframes`
  0% {
    transform: translate(0,  0px);
  }
  50%  {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, -0px);
  }
`;

const floatBottom = keyframes`
  0% {
    transform: translate(0,  0px);
  }
  50%  {
    transform: translate(0, -15px);
  }
  100% {
    transform: translate(0, -0px);
  }
`;

const TitleDesktopStyle = {
  fontSize: '2.5rem',
  fontWeight: 500,
  whiteSpace: 'initial',
  textAlign: 'start',
  color: 'text.primary'
};

const TitleTabletStyle = {
  fontSize: '2.125rem',
  fontWeight: 500,
  whiteSpace: 'initial',
  textAlign: 'center',
  color: 'text.primary'
};

const TitleMobileStyle = {
  fontSize: '1.25rem',
  fontWeight: 500,
  whiteSpace: 'initial',
  textAlign: 'center',
  color: 'text.primary'
};

const HomeSection: React.FC = () => {
  const router = useRouter();
  const theme = useTheme();
  const [width] = useDeviceSize();
  const { t } = useTranslation('webSiteEn');

  return (
    <Box
      component="section"
      width="100%"
      sx={{
        ':first-of-type': {
          marginTop: {
            xs: IS_SANDBOX_ENV ? '112px' : '56px',
            lg: IS_SANDBOX_ENV ? '168px' : '112px',
            backgroundColor: theme.palette.background.paper,
            overflow: 'hidden'
          }
        }
      }}
    >
      <SectionBackground
        fullScreen
        backgroundOpacity={0.6}
        url="/images/carousel/banner-background-0.jpg"
        disableGradient
      >
        <BackgroundGradient zIndex="initial">
          <Box
            sx={{
              position: 'relative',
              width: {
                xs: '100%',
                sm: 592,
                md: 1056,
                lg: 1056,
                xl: 1264
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: { xs: 100, sm: 250, md: 600, lg: 700, xl: 900 },
                bottom: -150,
                zIndex: -1,
                animation: `${floatBottom} 6s infinite ease`,
                transform: {
                  xs: 'translateX(-50%) translateY(-50%)',
                  lg: 'translateX(-50%) translateY(-50%)'
                }
              }}
              width="343.11px"
              height="296.05px"
            >
              <Image
                priority
                layout="fill"
                objectFit="contain"
                src="/images/gem/bottom.svg"
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: { xs: 100, sm: 250, md: 600, lg: 700, xl: 900 },
                bottom: -45,
                zIndex: -1,
                animation: `${floatTop} 6s infinite ease`,
                transform: {
                  xs: 'translateX(-50%) translateY(-50%)',
                  lg: 'translateX(-50%) translateY(-50%)'
                }
              }}
              width="343.11px"
              height="399.67px"
            >
              <Image
                priority
                layout="fill"
                objectFit="contain"
                src="/images/gem/top.svg"
              />
            </Box>

            <Grid
              zIndex={10}
              container
              display="flex"
              alignItems="center"
              position="relative"
              maxWidth={{
                xs: '100%',
                sm: 592,
                md: 1056,
                lg: 1056,
                xl: 1264
              }}
              justifyContent="center"
              // sx={{
              //   paddingX: { xs: 2, sm: 4, md: 4, lg: 0 }
              // }}
            >
              <Grid container zIndex={1}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={6}
                  display="flex"
                  flexDirection="column"
                  borderRadius="24px"
                  height="fit-content"
                  alignItems="space-evenly"
                  justifyContent="center"
                  whiteSpace="nowrap"
                  minHeight="366px"
                  zIndex={1}
                >
                  {/* <Grow in mountOnEnter unmountOnExit> */}

                  <Box zIndex={1}>
                    <Condition>
                      <Condition.If
                        condition={width >= theme.breakpoints.values.lg}
                      >
                        <>
                          <Text variant="h1" sx={TitleDesktopStyle}>
                            {t('webSiteEn.BannerSection.banner1.titleComplete')}
                          </Text>
                          {/* <Text variant="h1" sx={TitleDesktopStyle}>
                            {t('webSiteEn.BannerSection.banner1.title2')}
                          </Text>
                          <Text variant="h1" sx={TitleDesktopStyle}>
                            {t('webSiteEn.BannerSection.banner1.title3')}
                          </Text> */}
                        </>
                      </Condition.If>
                      <Condition.ElseIf
                        condition={width >= theme.breakpoints.values.md}
                      >
                        <Text
                          textAlign={{ xs: 'center', md: 'start' }}
                          sx={TitleTabletStyle}
                          color="text.primary"
                        >
                          {t('webSiteEn.BannerSection.banner1.title')}{' '}
                          {t('webSiteEn.BannerSection.banner1.title2')}{' '}
                          {t('webSiteEn.BannerSection.banner1.title3')}
                        </Text>
                      </Condition.ElseIf>
                      <Condition.Else>
                        <>
                          <Text
                            textAlign={{ xs: 'center', md: 'start' }}
                            sx={TitleMobileStyle}
                            color="text.primary"
                          >
                            {t('webSiteEn.BannerSection.banner1.title')}{' '}
                            {t('webSiteEn.BannerSection.banner1.title2')}{' '}
                          </Text>
                          <Text
                            textAlign={{ xs: 'center', md: 'start' }}
                            sx={TitleMobileStyle}
                            color="text.primary"
                          >
                            {t('webSiteEn.BannerSection.banner1.title3')}
                          </Text>
                        </>
                      </Condition.Else>
                    </Condition>
                  </Box>
                  {/* </Grow> */}

                  {/* <Grow in mountOnEnter unmountOnExit {...{ timeout: 750 }}> */}
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent={{ xs: 'center', sm: 'center', lg: 'left' }}
                    direction={{ xs: 'column', md: 'row' }}
                    pt={10}
                    spacing={{ xs: 2, md: 4 }}
                  >
                    <Grid item>
                      <Button
                        data-mixpanel="homepage_cta_ourservices"
                        variant="contained"
                        size="large"
                        sx={{
                          height: { xs: '36px', lg: '56px' },
                          minWidth: '240px',
                          fontWeight: 700
                        }}
                        onClick={() => {
                          router.push(Routes.WS_SERVICES);
                        }}
                        startIcon={
                          <ArrowRightIcon width="24px" height="24px" />
                        }
                      >
                        {t('webSiteEn.BannerSection.banner1.button')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        data-mixpanel="homepage_cta_ourservices"
                        variant="contained"
                        size="large"
                        sx={{
                          backgroundColor: 'divider',
                          color: theme.palette.primaryOverlays[200],
                          height: { xs: '36px', lg: '56px' },
                          minWidth: '240px',
                          fontWeight: 700,
                          ':hover': {
                            backgroundColor: 'divider',
                            color: theme.palette.primaryOverlays[200]
                          }
                        }}
                        onClick={() => {
                          router.push(Routes.WS_SERVICES);
                        }}
                      >
                        {t('webSiteEn.BannerSection.banner1.button2')}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* </Grow> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </BackgroundGradient>
      </SectionBackground>
    </Box>
  );
};

export default HomeSection;
