import {
  AirplaneDuotoneIcon,
  BankDuotoneIcon,
  BedDuotoneIcon,
  BuildingDuotoneIcon,
  DollarSignDuotoneIcon,
  GamesDuotoneIcon,
  HandHeartDuotoneIcon,
  InvestorsDuotoneIcon,
  LaptopCodeDuotoneIcon,
  LeafDuotoneIcon,
  MicDuotoneIcon,
  OthersDuotoneIcon,
  PhotoVideoDuotoneIcon,
  PopcornDuotoneIcon,
  RocketLaunchDuotoneIcon,
  StoreDuotoneIcon,
  ThemeDuotoneIcon,
  TicketDuotoneIcon,
  TradeDuotoneIcon
} from '@bws-bitfy/icons-react';

export function walletsJson(t) {
  return [
    {
      name: t('webSiteEn.TechnologyApplications.wallets.cases.wallet.button'),
      title: t('webSiteEn.TechnologyApplications.wallets.cases.wallet.title'),
      description: t(
        'webSiteEn.TechnologyApplications.wallets.cases.wallet.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.wallets.cases.wallet.targets.banks'
        ),
        t(
          'webSiteEn.TechnologyApplications.wallets.cases.wallet.targets.fintechs'
        ),
        t('webSiteEn.TechnologyApplications.wallets.cases.wallet.targets.game'),
        t(
          'webSiteEn.TechnologyApplications.wallets.cases.wallet.targets.developers'
        )
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.wallets.cases.wallet.advantages0'),
        t('webSiteEn.TechnologyApplications.wallets.cases.wallet.advantages1')
      ],
      icons: [
        <BankDuotoneIcon key="1" />,
        <DollarSignDuotoneIcon key="2" />,
        <GamesDuotoneIcon key="3" />,
        <LaptopCodeDuotoneIcon key="4" />
      ]
    }
  ];
}

export function assetJson(t) {
  return [
    {
      name: t('webSiteEn.TechnologyApplications.tokens.cases.platform.button'),
      title: t('webSiteEn.TechnologyApplications.tokens.cases.platform.title'),
      description: t(
        'webSiteEn.TechnologyApplications.tokens.cases.platform.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.platform.targets.banks'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.platform.targets.fintechs'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.platform.targets.sustainability'
        )
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.tokens.cases.platform.advantages0'),
        t('webSiteEn.TechnologyApplications.tokens.cases.platform.advantages1')
      ],
      icons: [
        <BankDuotoneIcon key="1" />,
        <DollarSignDuotoneIcon key="2" />,
        <LeafDuotoneIcon key="3" />
      ]
    },
    {
      name: t('webSiteEn.TechnologyApplications.tokens.cases.digital.button'),
      title: t('webSiteEn.TechnologyApplications.tokens.cases.digital.title'),
      description: t(
        'webSiteEn.TechnologyApplications.tokens.cases.digital.description'
      ),
      public: [
        t('webSiteEn.TechnologyApplications.tokens.cases.digital.targets.game'),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.digital.targets.fintechs'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.digital.targets.sustainability'
        )
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.tokens.cases.digital.advantages0')
      ],
      icons: [
        <GamesDuotoneIcon key="1" />,
        <DollarSignDuotoneIcon key="2" />,
        <LeafDuotoneIcon key="4" />
      ]
    },
    {
      name: t(
        'webSiteEn.TechnologyApplications.tokens.cases.gameCurrency.button'
      ),
      title: t(
        'webSiteEn.TechnologyApplications.tokens.cases.gameCurrency.title'
      ),
      description: t(
        'webSiteEn.TechnologyApplications.tokens.cases.gameCurrency.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.gameCurrency.targets.game'
        )
      ],
      advantages: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.gameCurrency.advantages0'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.gameCurrency.advantages1'
        )
      ],
      icons: [<GamesDuotoneIcon key="1" />]
    },
    {
      name: t(
        'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.button'
      ),
      title: t(
        'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.title'
      ),
      description: t(
        'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.targets.startups'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.targets.social'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.targets.investors'
        )
      ],
      advantages: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.advantages0'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.crowdfunding.advantages1'
        )
      ],
      icons: [
        <RocketLaunchDuotoneIcon key="1" />,
        <HandHeartDuotoneIcon key="2" />,
        <InvestorsDuotoneIcon key="4" />
      ]
    },
    {
      name: t('webSiteEn.TechnologyApplications.tokens.cases.fidelity.button'),
      title: t('webSiteEn.TechnologyApplications.tokens.cases.fidelity.title'),
      description: t(
        'webSiteEn.TechnologyApplications.tokens.cases.fidelity.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.fidelity.targets.retail'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.fidelity.targets.hospitality'
        ),
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.fidelity.targets.airline'
        )
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.tokens.cases.fidelity.advantages0'),
        t('webSiteEn.TechnologyApplications.tokens.cases.fidelity.advantages1'),
        t('webSiteEn.TechnologyApplications.tokens.cases.fidelity.advantages2')
      ],
      icons: [
        <StoreDuotoneIcon key="1" />,
        <BedDuotoneIcon key="2" />,
        <AirplaneDuotoneIcon key="3" />
      ]
    },
    {
      name: t('webSiteEn.TechnologyApplications.tokens.cases.reward.button'),
      title: t('webSiteEn.TechnologyApplications.tokens.cases.reward.title'),
      description: t(
        'webSiteEn.TechnologyApplications.tokens.cases.reward.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.tokens.cases.reward.targets.companies'
        )
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.tokens.cases.reward.advantages0'),
        t('webSiteEn.TechnologyApplications.tokens.cases.reward.advantages1'),
        t('webSiteEn.TechnologyApplications.tokens.cases.reward.advantages2')
      ],
      icons: [<BuildingDuotoneIcon key="1" />]
    }
  ];
}

export function nftJson(t) {
  return [
    {
      name: t('webSiteEn.TechnologyApplications.nfts.cases.platform.button'),
      title: t('webSiteEn.TechnologyApplications.nfts.cases.platform.title'),
      description: t(
        'webSiteEn.TechnologyApplications.nfts.cases.platform.description'
      ),
      public: [
        t('webSiteEn.TechnologyApplications.nfts.cases.platform.targets.banks'),
        t(
          'webSiteEn.TechnologyApplications.nfts.cases.platform.targets.fintechs'
        ),
        t('webSiteEn.TechnologyApplications.nfts.cases.platform.targets.others')
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.nfts.cases.platform.advantages0'),
        t('webSiteEn.TechnologyApplications.nfts.cases.platform.advantages1'),
        t('webSiteEn.TechnologyApplications.nfts.cases.platform.advantages2')
      ],
      icons: [
        <BankDuotoneIcon key="1" />,
        <DollarSignDuotoneIcon key="2" />,
        <OthersDuotoneIcon key="3" />
      ]
    },
    {
      name: t('webSiteEn.TechnologyApplications.nfts.cases.tickets.button'),
      title: t('webSiteEn.TechnologyApplications.nfts.cases.tickets.title'),
      description: t(
        'webSiteEn.TechnologyApplications.nfts.cases.tickets.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.nfts.cases.tickets.targets.concerts'
        ),
        t('webSiteEn.TechnologyApplications.nfts.cases.tickets.targets.movies'),
        t('webSiteEn.TechnologyApplications.nfts.cases.tickets.targets.others')
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.nfts.cases.tickets.advantages0'),
        t('webSiteEn.TechnologyApplications.nfts.cases.tickets.advantages1'),
        t('webSiteEn.TechnologyApplications.nfts.cases.tickets.advantages2')
      ],
      icons: [
        <MicDuotoneIcon key="1" />,
        <PopcornDuotoneIcon key="2" />,
        <TicketDuotoneIcon key="3" />
      ]
    },
    {
      name: t('webSiteEn.TechnologyApplications.nfts.cases.art.button'),
      title: t('webSiteEn.TechnologyApplications.nfts.cases.art.title'),
      description: t(
        'webSiteEn.TechnologyApplications.nfts.cases.art.description'
      ),
      public: [
        t('webSiteEn.TechnologyApplications.nfts.cases.art.targets.artists'),
        t('webSiteEn.TechnologyApplications.nfts.cases.art.targets.collectors')
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.nfts.cases.art.advantages0'),
        t('webSiteEn.TechnologyApplications.nfts.cases.art.advantages1'),
        t('webSiteEn.TechnologyApplications.nfts.cases.art.advantages2')
      ],
      icons: [<ThemeDuotoneIcon key="1" />, <PhotoVideoDuotoneIcon key="2" />]
    }
  ];
}

export function tradeSwapJson(t) {
  return [
    {
      name: t('webSiteEn.TechnologyApplications.trade.cases.broker.button'),
      title: t('webSiteEn.TechnologyApplications.trade.cases.broker.title'),
      description: t(
        'webSiteEn.TechnologyApplications.trade.cases.broker.description'
      ),
      public: [
        t(
          'webSiteEn.TechnologyApplications.trade.cases.broker.targets.companies'
        )
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.trade.cases.broker.advantages0'),
        t('webSiteEn.TechnologyApplications.trade.cases.broker.advantages1')
      ],
      icons: [<BuildingDuotoneIcon key="1" />]
    },
    {
      name: t('webSiteEn.TechnologyApplications.trade.cases.custody.button'),
      title: t('webSiteEn.TechnologyApplications.trade.cases.custody.title'),
      description: t(
        'webSiteEn.TechnologyApplications.trade.cases.custody.description'
      ),
      public: [
        t('webSiteEn.TechnologyApplications.trade.cases.custody.targets.banks'),
        t('webSiteEn.TechnologyApplications.trade.cases.custody.targets.funds')
      ],
      advantages: [
        t('webSiteEn.TechnologyApplications.trade.cases.custody.advantages0'),
        t('webSiteEn.TechnologyApplications.trade.cases.custody.advantages1')
      ],
      icons: [<BankDuotoneIcon key="1" />, <TradeDuotoneIcon key="2" />]
    }
  ];
}
