import {
  BankDuotoneIcon,
  QuoteLeftDuotoneIcon,
  QuoteRightDuotoneIcon
} from '@bws-bitfy/icons-react';
import { Box, Divider, Grid, useTheme } from '@mui/material';
import Text from 'components/atoms/Text';
import SectionBackground from 'components/website/molecules/sectionBackground';
import Image from 'next/image';

const UseCasesSection: React.FC = () => {
  const theme = useTheme();

  return (
    <section style={{ width: '100%', position: 'relative' }}>
      <SectionBackground>
        <Grid container paddingY={8} borderRadius="64px">
          <Grid
            xs={12}
            md={12}
            pb={{ xs: '16px', lg: 8 }}
            item
            display="flex"
            alignItems="center"
            flexDirection="column"
            paddingX={{ xs: 2, lg: 8 }}
          >
            <Text
              color="text.primary"
              variant="h3"
              fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
              pb={{ xs: '16px', md: 2 }}
              fontWeight={500}
              textAlign="center"
              intlPath="webSiteEn.UseCasesSection.title"
            />
            <Text
              color="text.secondary"
              fontSize={{ xs: '1rem', md: '1.5rem', lg: '1.5rem' }}
              textAlign="center"
              fontWeight={500}
              intlPath="webSiteEn.UseCasesSection.description"
            />
          </Grid>

          <Grid spacing={6} container item>
            <Grid container item xs={12} justifyContent="center" md={6}>
              <Grid item>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                >
                  <BankDuotoneIcon fill={theme.palette.primary.main} />
                  <Text
                    variant="h5"
                    fontWeight={500}
                    textAlign="center"
                    color={theme.palette.primary.main}
                    intlPath="webSiteEn.UseCasesSection.banking_and_finance"
                  />
                </Box>
                <Box>
                  <Text
                    color="text.primary"
                    variant="h6"
                    fontWeight={400}
                    intlPath="webSiteEn.UseCasesSection.description"
                  />
                </Box>
              </Grid>
              <Box marginTop={6}>
                <Box display="flex" gap={4}>
                  <Image
                    width={96}
                    height={96}
                    src="/images/website-brands/bb-logo.svg"
                  />
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <Image
                    width={96}
                    height={96}
                    src="/images/website-brands/visa-rounded-logo.svg"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop={6}
                >
                  <Image
                    width={48}
                    height={48}
                    src="/images/website-brands/pixcred-rounded-logo.svg"
                  />
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <Image
                    width={48}
                    height={48}
                    src="/images/website-brands/shipay-rounded-logo.svg"
                  />
                  <Box>
                    <Divider orientation="vertical" />
                  </Box>
                  <Image
                    width={48}
                    height={48}
                    src="/images/website-brands/fastcash-rounded-logo.svg"
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item container xs={12} md={6} alignItems="center">
              <Grid container wrap="nowrap" item justifyContent="space-between">
                <Grid item xs={1}>
                  <QuoteLeftDuotoneIcon
                    fill={theme.palette.primary.main}
                    width={34}
                    height={34}
                  />
                </Grid>
                <Grid item maxWidth={376}>
                  <Text
                    color="text.primary"
                    variant="h6"
                    textAlign="center"
                    fontWeight={400}
                    intlPath="webSiteEn.UseCasesSection.use_case_text"
                  />
                </Grid>
                <Grid item xs={1} alignSelf="end">
                  <QuoteRightDuotoneIcon
                    fill={theme.palette.primary.main}
                    width={34}
                    height={34}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionBackground>
    </section>
  );
};

export default UseCasesSection;
