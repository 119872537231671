import {
  GemIcon,
  PhotoVideoIcon,
  SwapIcon,
  WalletIcon
} from '@bws-bitfy/icons-react';
import { Box, Grid } from '@mui/material';
import Text from 'components/atoms/Text';
import ButtonGroup from 'components/molecules/ButtonGroup';
import ChipBanner from 'components/website/molecules/ChipBanner';
import SectionBackground from 'components/website/molecules/sectionBackground';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n';
import * as chips from './chipJson';

const BannersSection: React.FC = () => {
  const { assetJson, nftJson, tradeSwapJson, walletsJson } = chips;
  const { t } = useTranslation('webSiteEn');
  const [recurrence, setRecurrence] = useState(`wallets`);
  const [recurrenceJson, setRecurrenceJson] = useState(walletsJson(t));

  useEffect(() => {}, [chips]);

  const buttons = [
    {
      title: t('webSiteEn.TechnologyApplications.wallets.title'),
      slug: t('webSiteEn.TechnologyApplications.wallets.slug'),
      icon: <WalletIcon />
    },
    {
      title: t('webSiteEn.TechnologyApplications.tokens.title'),
      slug: t('webSiteEn.TechnologyApplications.tokens.slug'),
      icon: <GemIcon />
    },
    {
      title: t('webSiteEn.TechnologyApplications.nfts.title'),
      slug: t('webSiteEn.TechnologyApplications.nfts.slug'),
      icon: <PhotoVideoIcon />
    },
    {
      title: t('webSiteEn.TechnologyApplications.trade.title'),
      slug: t('webSiteEn.TechnologyApplications.trade.slug'),
      icon: <SwapIcon />
    }
  ];

  useEffect(() => {
    if (recurrence === 'wallets') setRecurrenceJson(walletsJson(t));
    if (recurrence === 'assetTokenization') setRecurrenceJson(assetJson(t));
    if (recurrence === 'nfts') setRecurrenceJson(nftJson(t));
    if (recurrence === 'tradeSwap') setRecurrenceJson(tradeSwapJson(t));
  }, [recurrence, i18n.language]);

  return (
    <Box component="section" width="100%">
      <SectionBackground backgroundOpacity={0.9}>
        <Grid container>
          <Grid xs={12} item display="flex" justifyContent="center" pb={4}>
            <Text
              fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
              color="text.primary"
              fontWeight={500}
              whiteSpace="nowrap"
            >
              {t('webSiteEn.TechnologyApplications.title')}
            </Text>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" pb={6}>
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: { xs: 'none', lg: 'flex' }
              }}
            >
              <ButtonGroup
                alignCenter
                width="200px"
                minHeight="48px"
                height="48px"
                selected={recurrence}
                onChange={(value) => setRecurrence(value)}
                buttons={buttons}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: { xs: 'initial', lg: 'none' }
              }}
            >
              <Box pb={2} width="100%">
                <ButtonGroup
                  width="100%"
                  minHeight="48px"
                  height="48px"
                  selected={recurrence}
                  onChange={(value) => setRecurrence(value)}
                  buttons={[buttons[0], buttons[1]]}
                />
              </Box>

              <ButtonGroup
                width="100%"
                minHeight="48px"
                height="48px"
                selected={recurrence}
                onChange={(value) => setRecurrence(value)}
                buttons={[buttons[2], buttons[3]]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} pb={6}>
            <ChipBanner recurrence={recurrence} data={recurrenceJson} />
          </Grid>
        </Grid>
      </SectionBackground>
    </Box>
  );
};

export default BannersSection;
