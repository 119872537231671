import { Grid } from '@mui/material';
import Text from 'components/atoms/Text';
import SectionBackground from 'components/website/molecules/sectionBackground';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

interface IconComponentProps {
  name: string;
  iconSize: number;
}

const IconComponent = ({ name, iconSize }: IconComponentProps) => {
  return (
    <Image
      src={`/images/networks/${name?.replace(' ', '_')?.toLowerCase()}.svg`}
      width={iconSize}
      height={iconSize}
    />
  );
};

const IconComponentSmall = ({ name, iconSize }: IconComponentProps) => {
  return (
    <Image
      src={`/images/networks/${name?.replace(' ', '_')?.toLowerCase()}.svg`}
      width={iconSize}
      height={iconSize}
    />
  );
};

const NetworksSection: React.FC = () => {
  const { t } = useTranslation('webSiteEn');

  return (
    <section style={{ width: '100%' }}>
      <SectionBackground
        backgroundOpacity={0.34}
        url="/images/sections-background/blockchains-section-background.jpg"
      >
        <Grid zIndex={1} alignItems="center" container>
          <Grid item xs={12}>
            <Grid
              pb={{ xs: '16px', md: '32px', lg: '57px' }}
              item
              xs={12}
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <Text
                textAlign="center"
                fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
                color="text.primary"
                fontWeight={500}
                display={{ xs: 'none', lg: 'inherit' }}
              >
                {t('webSiteEn.NetworksSection.title')}
              </Text>
              <Text
                textAlign="center"
                fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
                color="text.primary"
                fontWeight={500}
                display={{ xs: 'inherit', lg: 'none' }}
              >
                {t('webSiteEn.NetworksSection.titlePart1on720')}
              </Text>
              <Text
                textAlign="center"
                fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
                color="text.primary"
                fontWeight={500}
                display={{ xs: 'inherit', lg: 'none' }}
                whiteSpace="nowrap"
              >
                {t('webSiteEn.NetworksSection.titlePart2on720')}
              </Text>
              <Text
                textAlign="center"
                fontSize={40}
                color="text.primary"
                fontWeight={500}
                display={{ xs: 'none' }}
              >
                {t('webSiteEn.NetworksSection.title')}
              </Text>
              <Text
                fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
                color="text.secondary"
                paddingTop="16px"
                fontWeight={500}
              >
                {t('webSiteEn.NetworksSection.description')}
              </Text>
            </Grid>
            <Grid
              item
              sx={{
                flexWrap: 'wrap',
                rowGap: 3,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                columnGap: 3
              }}
              xs={12}
            >
              <IconComponent iconSize={72} name="bitcoin" />
              <IconComponent iconSize={72} name="ethereum" />
              <IconComponent iconSize={72} name="binance" />
              <IconComponent iconSize={72} name="avalanche" />
              <IconComponent iconSize={72} name="polygon" />
              <IconComponent iconSize={72} name="hyperledger_besu" />
            </Grid>
            <Grid
              item
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '40px',
                paddingBottom: '16px',
                display: { xs: 'none', md: 'flex' },
                fontWeight: 500
              }}
            >
              <Text
                color="text.secondary"
                intlPath="common_translations.status_labels.soon"
              />
            </Grid>

            <Grid
              item
              sx={{
                flexWrap: 'wrap',
                rowGap: 3,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                columnGap: 3,
                opacity: '0.5'
              }}
              xs={12}
            >
              <IconComponent iconSize={48} name="polkadot" />
              <IconComponent iconSize={48} name="solana" />
              <IconComponent iconSize={48} name="celo" />
              <IconComponent iconSize={48} name="algorand" />
            </Grid>
            <Grid
              item
              sx={{
                flexWrap: 'wrap',
                rowGap: 3,
                display: { xs: 'flex', md: 'none' },
                justifyContent: { xs: 'center', md: 'space-between' },
                columnGap: 3
              }}
              xs={12}
            >
              <IconComponentSmall iconSize={48} name="bitcoin" />
              <IconComponentSmall iconSize={48} name="ethereum" />
              <IconComponentSmall iconSize={48} name="binance" />
              <IconComponentSmall iconSize={48} name="avalanche" />
              <IconComponentSmall iconSize={48} name="polygon" />
              <IconComponentSmall iconSize={48} name="hyperledger_besu" />
            </Grid>
            <Grid
              item
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '32px',
                paddingBottom: '16px',
                display: { xs: 'flex', md: 'none' },
                fontWeight: 500
              }}
            >
              <Text
                color="text.secondary"
                intlPath="common_translations.status_labels.soon"
              />
            </Grid>
            <Grid
              item
              sx={{
                flexWrap: 'wrap',
                rowGap: 3,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'center',
                columnGap: 1,
                opacity: '0.5'
              }}
              xs={12}
            >
              <IconComponentSmall iconSize={24} name="polkadot" />
              <IconComponentSmall iconSize={24} name="solana" />
              <IconComponentSmall iconSize={24} name="celo" />
              <IconComponentSmall iconSize={24} name="algorand" />
            </Grid>
          </Grid>
        </Grid>
      </SectionBackground>
    </section>
  );
};

export default NetworksSection;
