import { Grid, useTheme } from '@mui/material';
import Text from 'components/atoms/Text';
import BannerCard from 'components/website/molecules/BannerCard';
import BannerCardResponsiveSmall from 'components/website/molecules/BannerCardResponsiveSmall';
import SectionBackground from 'components/website/molecules/sectionBackground';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CardSection: React.FC = () => {
  const { t } = useTranslation('webSiteEn');
  const theme = useTheme();
  const [focusedCardIndex, setFocusedCardIndex] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setFocusedCardIndex((prevCount) => (prevCount + 1) % 3);
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  return (
    <SectionBackground>
      <Grid zIndex={1} display="flex" paddingTop={4} alignItems="center">
        <Grid columnGap={3}>
          <Grid xs={12} item display="flex" justifyContent="center">
            <Text
              fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
              color="text.primary"
              fontWeight={500}
            >
              {t('webSiteEn.CardSection.title')}
            </Text>
          </Grid>
          <Grid xs={12} item display="flex" justifyContent="center">
            <Text
              fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
              color="text.secondary"
              fontWeight={500}
            >
              {t('webSiteEn.CardSection.description')}.
            </Text>
          </Grid>
          <Grid
            columnSpacing={3}
            rowGap={3}
            pt={{ xs: '32px', lg: '64px' }}
            container
            justifyContent="center"
          >
            <Grid
              item
              xs={11}
              sm={6}
              md={4}
              lg={4}
              display={{ xs: 'none', lg: 'initial' }}
            >
              <BannerCard
                name="ease"
                title={t('webSiteEn.CardSection.card1.title')}
                description={t('webSiteEn.CardSection.card1.description')}
                color={theme.palette.primaryOverlays[200]}
              />
            </Grid>
            <Grid
              item
              xs={11}
              sm={6}
              md={4}
              lg={4}
              display={{ xs: 'none', lg: 'initial' }}
            >
              <BannerCard
                name="economy"
                title={t('webSiteEn.CardSection.card2.title')}
                description={t('webSiteEn.CardSection.card2.description')}
                color="#B994F9"
              />
            </Grid>
            <Grid
              item
              xs={11}
              sm={12}
              md={4}
              lg={4}
              marginBottom={{ sm: '150px', md: 0 }}
              display={{ xs: 'none', lg: 'initial' }}
            >
              <BannerCard
                name="accessibility"
                title={t('webSiteEn.CardSection.card3.title')}
                description={t('webSiteEn.CardSection.card3.description')}
                color="#A2A9FC"
              />
            </Grid>
            <Grid item xs={12} display={{ xs: 'initial', lg: 'none' }}>
              <BannerCardResponsiveSmall
                name="ease"
                index={0}
                focusedCardIndex={focusedCardIndex}
                title={t('webSiteEn.CardSection.card1.title')}
                description={t('webSiteEn.CardSection.card1.description')}
                iconUrl="/images/card-section/ease.svg"
                color={theme.palette.primaryOverlays[200]}
              />
            </Grid>
            <Grid item xs={12} display={{ xs: 'initial', lg: 'none' }}>
              <BannerCardResponsiveSmall
                name="economy"
                index={1}
                focusedCardIndex={focusedCardIndex}
                title={t('webSiteEn.CardSection.card2.title')}
                description={t('webSiteEn.CardSection.card2.description')}
                iconUrl=""
                color="#B994F9"
              />
            </Grid>
            <Grid item xs={12} display={{ xs: 'initial', lg: 'none' }}>
              <BannerCardResponsiveSmall
                name="accessibility"
                index={2}
                focusedCardIndex={focusedCardIndex}
                title={t('webSiteEn.CardSection.card3.title')}
                description={t('webSiteEn.CardSection.card3.description')}
                iconUrl="/images/card-section/accessibility.svg"
                color="#A2A9FC"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SectionBackground>
  );
};

export default CardSection;
