import WebsiteTemplate from 'components/templates/website';
import ApiDocsSection from 'components/website/organisms/ApiDocsSection';
import BannersSection from 'components/website/organisms/BannersSection';
import CardSection from 'components/website/organisms/CardSection';
import ContactSection from 'components/website/organisms/ContactSection';
import HomeSection from 'components/website/organisms/HomeSection';
import InvestorsSection from 'components/website/organisms/InvestorsSection';
import NetworksSection from 'components/website/organisms/NetworksSection';
import UseCasesSection from 'components/website/organisms/UseCasesSection';

const WebSite: React.FC = () => {
  return (
    <WebsiteTemplate>
      <HomeSection />
      <BannersSection />
      <NetworksSection />
      <ApiDocsSection />

      <CardSection />
      <UseCasesSection />
      <InvestorsSection />
      <ContactSection trackingTitle="homepage" />
    </WebsiteTemplate>
  );
};
export default WebSite;
