import { CodeIcon } from '@bws-bitfy/icons-react';
import { ButtonProps, Link, SxProps, useTheme } from '@mui/material';
import Tooltip from 'components/molecules/Tooltip';
import useDeviceSize from 'hooks/useDeviceSize';
import { TranslationValuePath } from 'i18n';
import Button from '../Button';
import Condition from '../Condition';
import IconButton from '../IconButton/IconButton';

export type APIDocServiceName =
  | 'WALLETS_API_DOC'
  | 'PUBLIC_KEY_API_DOC'
  | 'AUTHENTICATION_API_DOC'
  | 'TOKEN_CONTRACTS_API_DOC'
  | 'TRANSACTIONS_API_DOC'
  | 'EXCHANGES_API_DOC'
  | 'ASSET_TOKENIZATION_API_DOC'
  | 'NFT_COLLECTIONS_API_DOC'
  | 'WEBHOOKS_API_DOC';

interface APIDocButtonProps extends ButtonProps {
  apiDocName?: APIDocServiceName;
  label?: string;
  labelIntlPath?: TranslationValuePath;
  useIconButton?: boolean;
  iconButtonSX?: SxProps;
  alwaysShowLabel?: boolean;
  doNotShowTooltip?: boolean;
  startIcon?: React.ReactNode;
  'data-mixpanel'?: string;
}

const apiDocId = {
  AUTHENTICATION_API_DOC: '#2bb0dc89-1e8b-4d1f-935d-53e782dff8d1',
  WALLETS_API_DOC: '#7d9bb408-38c5-4ff5-948f-f6104366fcdd',
  TOKEN_CONTRACTS_API_DOC: '#d1be7ac6-da1a-4e2c-b583-4bb2b33ceb5e',
  PUBLIC_KEY_API_DOC: '#13255e92-43b6-4a54-b9fe-712c1d3b50bd',
  EXCHANGES_API_DOC: '#4e933239-ebf0-476e-a4e7-3e13869df03b',
  TRANSACTIONS_API_DOC: '#ea5de30d-cb45-44a0-9aaf-1df791f6ecaf',
  ASSET_TOKENIZATION_API_DOC: '#e210fb9b-3be6-46e5-a716-bdef2d3828fe',
  NFT_COLLECTIONS_API_DOC: '#1c70c703-f971-467c-937a-cda29e44732b',
  WEBHOOKS_API_DOC: '#ed92604b-942d-4889-b5e1-5b9417feef19'
};

const APIDocButton: React.FC<APIDocButtonProps> = ({
  children,
  labelIntlPath,
  label,
  apiDocName: apiDocServiceName,
  useIconButton,
  alwaysShowLabel,
  iconButtonSX,
  doNotShowTooltip,
  ...props
}) => {
  const [width] = useDeviceSize();
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      placement="top"
      titleIntlPath="common_translations.documentation_tooltip"
      disableHoverListener={doNotShowTooltip}
      disableFocusListener={doNotShowTooltip}
    >
      <Link
        // component="button"
        target="_blank"
        underline="none"
        onClick={(event) => {
          event.stopPropagation();
        }}
        // href={`https://docs.blockchainasaservice.com.br/${
        //   apiDocServiceName ? apiDocId[apiDocServiceName] : ''
        // }`}
        href={`https://docs.bitfybaas.com/${
          apiDocServiceName ? apiDocId[apiDocServiceName] : ''
        }`}
      >
        <Condition>
          <Condition.If
            condition={
              width < theme.breakpoints.values.md &&
              !alwaysShowLabel &&
              !useIconButton
            }
          >
            <Button {...props} intlpath={labelIntlPath}>
              {label}
            </Button>
          </Condition.If>
          <Condition.ElseIf
            condition={
              (width > theme.breakpoints.values.md || alwaysShowLabel) &&
              !useIconButton
            }
          >
            <Button
              {...props}
              intlpath={
                labelIntlPath ?? 'common_translations.documentation_button'
              }
            >
              {label}
            </Button>
          </Condition.ElseIf>
          <Condition.ElseIf condition={useIconButton}>
            <IconButton sx={iconButtonSX} {...props} key={2}>
              <CodeIcon fill={theme.palette.primaryOverlays[200]} />
            </IconButton>
          </Condition.ElseIf>
        </Condition>
      </Link>
    </Tooltip>
  );
};

export default APIDocButton;
