import { Box, Grid } from '@mui/material';
import Text from 'components/atoms/Text';
import SectionBackground from 'components/website/molecules/sectionBackground';
import Image from 'next/image';

type Brand = { logoSrc?: string; companyName: string; altImg?: string };

const InvestorsSection: React.FC = () => {
  const investorsBrand: Brand[] = [
    {
      logoSrc: '/images/website-brands/bb-logo.svg',
      altImg: 'Logo do Banco do Brasil',
      companyName: 'Banco do Brasil'
    },
    {
      logoSrc: '/images/website-brands/borderless-logo.svg',
      altImg: 'Logo de Borderless Capital',
      companyName: 'Borderless Capital'
    },
    {
      logoSrc: '/images/website-brands/algo-logo.svg',
      altImg: 'Logo da Algorand',
      companyName: 'Algorand'
    },
    {
      logoSrc: '/images/website-brands/dash-logo.svg',
      altImg: 'Logo de Dash',
      companyName: 'Dash'
    }
  ];

  const acceleratedByBrands: Brand[] = [
    {
      logoSrc: '/images/website-brands/mastercard-logo.svg',
      altImg: 'Logo de Mastercard',
      companyName: 'Mastercard'
    },
    {
      logoSrc: '/images/website-brands/visa-logo.svg',
      altImg: 'Logo de Visa',
      companyName: 'Visa'
    }
  ];

  return (
    <section style={{ width: '100%' }}>
      <SectionBackground
        noPadding
        backgroundOpacity={0.34}
        url="/images/sections-background/ufo-background.jpg"
      >
        <Grid
          container
          alignItems={{ xs: 'end', md: 'end' }}
          display="flex"
          height={390}
        >
          <Grid
            xs={12}
            item
            display={{ xs: 'flex', md: 'initial' }}
            maxHeight="91px"
            justifyContent={{ xs: 'center', md: 'start' }}
          >
            <Text
              color="text.primary"
              variant="h1"
              fontSize={{ xs: '1.25rem', md: '2.5rem' }}
              fontWeight={500}
              pb={8}
              intlPath="webSiteEn.InvestorsSection.title"
            />
          </Grid>
        </Grid>
      </SectionBackground>

      <SectionBackground noPadding minHeight={400}>
        <Grid spacing={3} pt={6} container>
          <Grid
            container
            item
            spacing={3}
            flexDirection="column"
            xs={12}
            md={6}
          >
            <Grid
              item
              display={{ xs: 'flex', md: 'initial' }}
              justifyContent={{ xs: 'center', md: 'start' }}
            >
              <Text
                color="text.secondary"
                variant="h5"
                fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
                fontWeight={500}
                intlPath="webSiteEn.InvestorsSection.investors"
              />
            </Grid>
            {investorsBrand.map((item) => (
              <Grid
                key={item.companyName}
                container
                item
                alignItems="center"
                display={{ xs: 'flex', md: 'initial' }}
                justifyContent={{ xs: 'center', md: 'start' }}
              >
                <Box
                  width={{ xs: '200px', md: '300px' }}
                  display="flex"
                  alignItems={{ xs: 'center', md: 'center' }}
                >
                  <Image
                    priority
                    width={48}
                    height={48}
                    src={item.logoSrc}
                    alt={item.altImg}
                  />
                  <Text
                    color="text.primary"
                    marginLeft={2}
                    variant="h5"
                    fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
                    fontWeight={500}
                  >
                    {item.companyName}
                  </Text>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            item
            spacing={3}
            flexDirection="column"
            xs={12}
            md={6}
          >
            <Grid
              item
              display={{ xs: 'flex', md: 'initial' }}
              justifyContent={{ xs: 'center', md: 'start' }}
            >
              <Text
                color="text.secondary"
                variant="h5"
                fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
                fontWeight={500}
                intlPath="webSiteEn.InvestorsSection.accelerated_by"
              />
            </Grid>

            {acceleratedByBrands.map((item) => (
              <Grid
                key={item.companyName}
                container
                item
                alignItems="center"
                display={{ xs: 'flex', md: 'initial' }}
                justifyContent={{ xs: 'center', md: 'start' }}
              >
                <Box
                  width={{ xs: '200px', md: '300px' }}
                  display="flex"
                  alignItems={{ xs: 'center', md: 'center' }}
                >
                  <Image
                    priority
                    width={48}
                    height={48}
                    src={item.logoSrc}
                    alt={item.altImg}
                  />
                  <Text
                    color="text.primary"
                    variant="h5"
                    marginLeft={2}
                    fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
                    fontWeight={500}
                  >
                    {item.companyName}
                  </Text>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </SectionBackground>
    </section>
  );
};

export default InvestorsSection;
