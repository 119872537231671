import { CodeDuotoneIcon, ExternalLinkIcon } from '@bws-bitfy/icons-react';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import APIDocButton from 'components/atoms/APIDocButton';
import Text from 'components/atoms/Text';
import SectionBackground from 'components/website/molecules/sectionBackground';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const ApiDocsSection: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('webSiteEn');

  return (
    <section style={{ width: '100%' }}>
      <SectionBackground
        backgroundOpacity={0.5}
        url="/images/sections-background/api-docs-background-section.jpg"
      >
        <Grid
          zIndex={1}
          container
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          <Grid
            order={{ xs: 2, lg: 1 }}
            xs={12}
            sm={12}
            lg={6}
            item
            display={{ xs: 'flex', lg: 'initial' }}
            flexDirection="column"
            alignItems={{ xs: 'center' }}
          >
            <Box display="flex">
              <Box display={{ xs: 'none', md: 'inherit' }}>
                <CodeDuotoneIcon
                  width={64}
                  height={64}
                  fill={theme.palette.text.primary}
                />
              </Box>
              <Box
                display={{ xs: 'flex', md: 'none' }}
                justifyContent="center"
                alignItems="center"
                paddingBottom="16px"
              >
                <CodeDuotoneIcon
                  width={24}
                  height={24}
                  fill={theme.palette.text.primary}
                />
              </Box>

              <Text
                color="textPrimary"
                fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
                pb={2}
                fontWeight={500}
                pl={{ xs: '16px', md: '20px' }}
              >
                {t('webSiteEn.ApiDocsSection.title')}
              </Text>
            </Box>

            <Text
              color="text.primary"
              fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
              pb={{ xs: '16px', lg: '32px' }}
              fontWeight={500}
            >
              {t('webSiteEn.ApiDocsSection.subTitle')}
            </Text>
            <Text
              textAlign={{ xs: 'center', lg: 'start' }}
              maxWidth={{ xs: '400px', lg: '510px' }}
              color="textSecondary"
              fontSize={{ xs: '0.875rem', lg: '1rem' }}
              pb={{ xs: '16px', sm: '24px', lg: '80px' }}
            >
              {t('webSiteEn.ApiDocsSection.description')}
            </Text>
            <APIDocButton
              sx={{
                height: '36px',
                fontWeight: 500
              }}
              variant="contained"
              label={t('webSiteEn.ApiDocsSection.button')}
              doNotShowTooltip
              data-mixpanel="homepage_cta_docs"
              startIcon={<ExternalLinkIcon />}
            />
          </Grid>
          <Grid
            order={{ xs: 1, lg: 2 }}
            mb={{ xs: 4, lg: 0 }}
            px={{ xs: 3, sm: 0 }}
            item
            xs={12}
            sm={12}
            lg={6}
            maxHeight="394px"
            display="flex"
            justifyContent={{ xs: 'center', lg: 'flex-end' }}
            alignItems="flex-start"
          >
            <Image width={400} height={394} src="/images/code.svg" />
          </Grid>
        </Grid>
      </SectionBackground>
    </section>
  );
};

export default ApiDocsSection;
