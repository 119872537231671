import { Box, Card, Fade } from '@mui/material';
import Text from 'components/atoms/Text';
import Image from 'next/image';
import { useState } from 'react';

export interface CircleIconProps {
  title: string;
  description: string;
  color?: string;
  iconUrl?: string;
  name?: string;
}

const BannerCard: React.FC<CircleIconProps> = ({ ...props }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Card
      elevation={0}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '330px',
        padding: `32px`,
        height: '100%',
        // border: `1px solid ${theme.palette.divider}`,
        ':hover': {
          boxShadow: '0px 0px 16px rgba(185, 148, 249, 0.34)'
        },
        transition: '0.4s'
      }}
    >
      <Box width="146px" height="146px">
        <Fade in={!isHovered} {...{ timeout: 300 }}>
          <Box position="absolute">
            <Image
              src={`/images/card-section/${props.name}.png`}
              width={146}
              height={146}
            />
          </Box>
        </Fade>
        <Fade in={isHovered} {...{ timeout: 300 }}>
          <Box>
            <Image
              src={`/images/card-section/${props.name}-focused.png`}
              width={146}
              height={146}
            />
          </Box>
        </Fade>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 2
        }}
      >
        <Text
          paddingBottom="30px"
          color={isHovered ? props.color : `text.primary`}
          fontWeight={700}
          variant="h5"
          sx={{
            transition: '0.4s'
          }}
          textAlign="center"
        >
          {props.title}
        </Text>
        <Text color="text.secondary" textAlign="center">
          {props.description}
        </Text>
      </Box>
    </Card>
  );
};

export default BannerCard;
