import {
  EmailDuotoneIcon,
  EmailIcon,
  ExternalLinkIcon,
  SendIcon
} from '@bws-bitfy/icons-react';
import { Box, Button, ButtonGroup, Grid, useTheme } from '@mui/material';
import APIDocButton from 'components/atoms/APIDocButton';
import CustomButton from 'components/atoms/Button';
import Text from 'components/atoms/Text';
import ContactMessageForm from 'components/molecules/ContactMessageForm';
import NewDialog from 'components/organisms/NewDialog';
import { HookCallbackFunctions } from 'hooks/types';
import useAuth from 'hooks/useAuth';
import Image from 'next/image';
import { SnackbarMessage, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IContactMessage, sendContactMessage } from 'services/contactUs';

interface ChipBannerItem {
  name: string;
  title: string;
  description: string;
  public: string[];
  advantages: string[];
  icons: React.ReactElement[];
}

interface ChipBannerProps {
  data: ChipBannerItem[];
  recurrence: string;
}

const ChipBanner = ({ data, recurrence }: ChipBannerProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const useAuthProperties = useAuth();
  const [chipSelected, setChipSelected] = useState(data[0]);
  const [loading, setLoading] = useState(false);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [isContactUsFormValid, setIsContactUsFormValid] =
    useState<boolean>(false);
  const { t } = useTranslation('webSiteEn');

  const handleContactMessage = async (
    contactMessage: IContactMessage,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onError, onSuccess } = callbacks ?? {};
    setLoading(true);

    await sendContactMessage(contactMessage, useAuthProperties.reCaptchaToken)
      .then((response) => {
        if (response.success) {
          enqueueSnackbar({
            type: 'success',
            title: response?.message
          } as unknown as SnackbarMessage);
          setOpenContactDialog(false);
          onSuccess?.(response);
        } else {
          enqueueSnackbar({
            type: 'error',
            title: response?.message || 'Error on send data'
          } as unknown as SnackbarMessage);
          onError?.(response);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setChipSelected(data[0]);
  }, [data]);

  return (
    <Box>
      <NewDialog
        titleIntlPath="webSiteEn.Contact.title"
        open={openContactDialog}
        confirmButtonLabelIntlPath="webSiteEn.Contact.form.button"
        fullHeight
        confirmButtonType="submit"
        showOnlyConfirmButton
        customConfirmButtonIcon={<SendIcon />}
        titleIcon={<EmailDuotoneIcon width={36} height={36} />}
        onClose={() => setOpenContactDialog(false)}
        formIdToSubmit="contact_us_form"
        disableConfirmButton={loading || !isContactUsFormValid}
        loading={loading}
        data-mixpanel="home_cta_forms"
      >
        <ContactMessageForm
          formId="contact_us_form"
          hideSendButton
          setIsFormValid={setIsContactUsFormValid}
          loading={loading}
          initialValues={{
            name: '',
            email: '',
            phone: '',
            message: ''
          }}
          onSubmit={handleContactMessage}
        />
      </NewDialog>
      <Box
        sx={{
          overflowX: 'scroll',
          '::-webkit-scrollbar-track': {
            display: 'none'
          },
          '::-webkit-scrollbar-thumb': {
            display: { xs: 'none', sm: 'initial' },
            background: 'transparent'
          },
          marginRight: { xs: '-16px', sm: '0px' }
        }}
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          sx={{ overflowX: 'hidden' }}
        >
          {data.map((item, index) => (
            <Button
              onClick={() => setChipSelected(item)}
              sx={{
                whiteSpace: 'nowrap',
                fontWeight: 500,
                variant: 'body2',
                color:
                  chipSelected.name === item.name
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
                backgroundColor: theme.palette.divider,
                borderColor: `${theme.palette.background.default} !important`,
                borderRadius: '8px',
                ':hover': {
                  backgroundColor: theme.palette.background.elevated,
                  color:
                    chipSelected.name === item.name
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
                }
              }}
              key={index}
            >
              {item.name}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: 4,
          borderRadius: '16px',
          mt: 2,
          position: 'relative',
          minHeight: `392px`
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Text variant="h5" color="text.primary">
              {chipSelected?.title}
            </Text>
            <Text color="text.secondary">{chipSelected?.description}</Text>
          </Grid>
          <Grid container item xs={12} lg={9} pt={4} display="flex">
            <Grid container item pb={{ xs: 0, lg: 8 }}>
              <Grid item xs={12} lg="auto" maxWidth={{ xs: '100%', lg: 200 }}>
                <Text color="text.primary">
                  {t('webSiteEn.TechnologyApplications.target')}
                </Text>
                {chipSelected?.public.map((item, index) => (
                  <Box
                    color="text.secondary"
                    key={index}
                    display="flex"
                    alignItems="center"
                    pt={2}
                    sx={{
                      svg: {
                        fill: theme.palette.iconSecondary
                      }
                    }}
                  >
                    <Box pr={1}>{chipSelected.icons[index]}</Box>
                    <Text color="text.secondary" key={index}>
                      {item}
                    </Text>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={8}
                pt={{ xs: 2, lg: 0 }}
                pl={{ xs: 0, lg: 4 }}
              >
                <Text color="text.primary">
                  {t('webSiteEn.TechnologyApplications.advantages')}
                </Text>
                {chipSelected?.advantages.map((item, index) => (
                  <Box
                    color="text.secondary"
                    key={index}
                    display="flex"
                    alignItems="center"
                  >
                    <Text pt={2} pr={1}>
                      •
                    </Text>
                    <Text
                      pt={2}
                      pr={{ xs: 2, md: 4 }}
                      color="text.secondary"
                      whiteSpace="pre-line"
                    >
                      {item}
                    </Text>
                  </Box>
                ))}
              </Grid>
            </Grid>

            <Grid
              item
              xs={7}
              display={{ xs: 'none', lg: 'flex' }}
              position="absolute"
              bottom="32px"
            >
              <CustomButton
                startIcon={<EmailIcon />}
                sx={{
                  backgroundColor: 'divider',
                  color: theme.palette.primary.main,
                  height: '36px',
                  mr: 2
                }}
                onClick={() => setOpenContactDialog(true)}
              >
                {t('webSiteEn.TechnologyApplications.buttons.contact')}
              </CustomButton>
              <APIDocButton
                sx={{
                  height: '36px',
                  fontWeight: 500,
                  backgroundColor: 'divider',
                  color: theme.palette.primary.main
                }}
                variant="contained"
                doNotShowTooltip
                data-mixpanel="homepage_cta_docs"
                startIcon={<ExternalLinkIcon />}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs="auto"
            position={{ xs: 'relative', lg: 'absolute' }}
            right="32px"
            top="32px"
            pl={{ xs: 0, md: 4, lg: 0 }}
            pb={{ xs: 0, md: 4, lg: 0 }}
          >
            <Image
              src={`/images/chip-banner/${recurrence}.gif`}
              width={326.633}
              height={328}
            />
          </Grid>
          <Grid item xs={12} display={{ xs: 'flex', lg: 'none' }} bottom="32px">
            <CustomButton
              startIcon={<EmailIcon />}
              sx={{
                backgroundColor: 'divider',
                color: theme.palette.primary.main,
                height: '36px',
                mr: 2
              }}
            >
              {t('webSiteEn.TechnologyApplications.buttons.contact')}
            </CustomButton>
            <APIDocButton
              sx={{
                height: '36px',
                fontWeight: 500,
                backgroundColor: 'divider',
                color: theme.palette.primary.main
              }}
              variant="contained"
              doNotShowTooltip
              data-mixpanel="homepage_cta_docs"
              startIcon={<ExternalLinkIcon />}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChipBanner;
